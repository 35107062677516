<template>
  <el-row :gutter="20">
    <el-col :span="4">
      <el-select filterable v-model="type" placeholder="请选择模型">
        <el-option
          v-for="item in typeList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </el-col>
    <el-col :span="4">
      <el-input
        placeholder="请输入分类关键字"
        v-model="searchVal"
        class="input-with-select"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="handleSearch"
        ></el-button>
      </el-input>
    </el-col>
    <el-col :span="2">
      <el-button type="primary" icon="el-icon-plus" @click="handleAddItem"
        >新增原材料</el-button
      >
    </el-col>
  </el-row>
</template>
<script>
export default {
  name: "Nav",
  props: {
    typeList: Array,
    typeId: Number,
  },
  data() {
    return {
      searchVal: "",
      type: null,
    };
  },
  watch: {
    typeId() {
      this.type = this.typeId;
    },
    type() {
      this.$emit("type", this.type);
    },
  },
  methods: {
    handleAddItem() {
      this.$emit("add");
    },
    //搜索
    handleSearch() {
      this.$emit("search", this.searchVal);
    },
  },
};
</script>
<style lang="less" scoped>
</style>