
<template>
  <div class="material-list">
    <material-nav
      :typeId="typeId"
      :typeList="typeList"
      @type="handleSelectType"
      @search="handleSearch"
      @add="handleAddItem"
    ></material-nav>
    <list
      :list="list"
      :modelFilter="modelFilter"
      :typeFilter="typeFilter"
      @edit="handleEditItem"
      @succ="handleSucc"
    ></list>
    <edit-material
      :show="show"
      :type="type"
      :item="item"
      :typeList="typeList"
      @succ="handleSucc"
    ></edit-material>
  </div>
</template>

<script>
import List from "./components/List";
import MaterialNav from "./components/Nav";
import EditMaterial from "./components/EditMaterial";
import { mapState } from "vuex";
export default {
  name: "MaterialList",
  components: { MaterialNav, List, EditMaterial },
  data() {
    return {
      show: false,
      type: "add",
      list: [],
      allList: [],
      typeList: [], //分类列表
      typeId: null,
      item: {},
      modelFilter: [],
      typeFilter: [],
    };
  },
  computed: {
    ...mapState(["modelList"]),
  },
  created() {
    this.getMaterialType();
    this.getGoodsModels();
  },
  methods: {
    async getGoodsModels() {
      await this.$store.dispatch("getGoodsModels");
      this.modelId = this.modelList[0].id;
      this.modelFilter = this.modelList.map((item) => {
        return {
          value: item.id,
          text: item.name,
        };
      });
    },
    //获取原材料
    async getMaterials() {
      this.allList = await this.$store.dispatch("getMaterials");
      this.list = this.allList.filter(
        (item) => item.category_id === this.typeId
      );
    },
    //获取原材料分类
    async getMaterialType() {
      const res = await this.$store.dispatch("getMaterialType");
      this.typeList = res.materialCategories;
      this.typeId = this.typeList[0].id;
      this.getMaterials();
      this.typeFilter = this.typeList.map((item) => {
        return {
          value: item.id,
          text: item.name,
        };
      });
    },
    //筛选分类
    handleSelectType(typeId) {
      this.typeId = typeId;
      this.list = this.allList.filter((item) => item.category_id === typeId);
    },
    //搜索
    handleSearch(val) {
      if (val === "") {
        this.list = this.allList;
        return;
      }
      this.list = this.list.reduce((prev, cur) => {
        if (cur.name.indexOf(val) > -1) {
          prev.push(cur);
        }
        return prev;
      }, []);
    },
    //点击弹出新增弹窗
    handleAddItem() {
      this.show = !this.show;
      this.type = "add";
    },
    handleEditItem(item) {
      this.item = item;
      this.show = !this.show;
      this.type = "edit";
    },
    //提交成功
    handleSucc() {
      this.getMaterials();
    },
  },
};
</script>
<style lang="less" scoped>
</style>