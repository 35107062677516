<template>
  <ky-dialog :title="title" :show="show" :hide="hide">
    <el-form
      slot="dialog-content"
      :label-position="labelPosition"
      label-width="100px"
      :model="fromData"
      :rules="rules"
      ref="fromData"
    >
      <el-form-item label="原材料名称:" prop="name">
        <el-input v-model="fromData.name"></el-input>
      </el-form-item>

      <el-form-item label="原材料类型:" prop="category_id">
        <el-select
          v-model="fromData.category_id"
          style="display: block"
          placeholder="请选择"
        >
          <el-option
            v-for="item in typeList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="排序:">
        <el-input v-model.number="fromData.sort"></el-input>
      </el-form-item>
      <el-form-item label="成本:" prop="price">
        <el-input v-model="fromData.price"></el-input>
      </el-form-item>
    </el-form>
    <span slot="dialog-footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="handleComfirm">确 定</el-button>
    </span>
  </ky-dialog>
</template>
<script>
import rules from "@/util/rules";
import { mapState } from "vuex";
export default {
  name: "EditMaterial",
  props: {
    type: String,
    show: Boolean,
    item: Object,
    typeList: Array,
  },
  data() {
    return {
      hide: false,
      title: "新增型号",
      labelPosition: "right",
      fromData: {
        id: "",
        category_id: "",
        name: "",
        price: "",
        sort: 1,
      },
      rules: {
        name: [rules.noNullBlur("请输入原材料名称")],
        price: [rules.numberBlur()],
        category_id: [rules.numberBlur()],
      },
    };
  },
  computed: {
    ...mapState(["modelList"]),
  },
  watch: {
    show(e) {
      if (this.type === "add") {
        this.title = "新增原材料";
        this.fromData = {
          id: "",
          category_id: "",
          name: "",
          price: "",
          sort: 1,
        };
      } else {
        this.title = "编辑原材料";
        this.fromData = {
          id: this.item.id,
          category_id: this.item.category_id,
          name: this.item.name,
          price: this.item.price,
          sort: this.item.sort,
        };
      }
    },
  },
  created() {
    this.getGoodsModels();
  },
  methods: {
    async getGoodsModels() {
      await this.$store.dispatch("getGoodsModels");
    },
    //取消
    handleCancel() {
      this.hide = !this.hide;
    },
    //确定
    handleComfirm() {
      const fromData = this.fromData;
      let data = {
        name: fromData.name,
        category_id: fromData.category_id,
        price: fromData.price,
        sort: fromData.sort,
      };
      if (this.type === "add") {
        this.handleAddComfirm(data);
        return;
      } else {
        data.material_id = fromData.id;
        this.handleEditComfirm(data);
      }
    },
    //新增提交
    async handleAddComfirm(fromData) {
      const res = await this.$store.dispatch("addMaterial", fromData);
      this.$tool.comfirmToast(res).then(() => {
        this.$emit("succ");
        this.hide = !this.hide;
      });
    },
    //编辑提交
    async handleEditComfirm(fromData) {
      const res = await this.$store.dispatch("editMaterial", fromData);
      this.$tool.comfirmToast(res).then(() => {
        this.$emit("succ");
        this.hide = !this.hide;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>